$maximum-width: 1536px;
$box-shadow: 0 0 1px rgba(67, 90, 111, 0.47),
  0 2px 4px -2px rgba(67, 90, 111, 0.3);
$app-bar-height: 88px;
$footer-height: 121px;
$min-height: calc(100vh - #{$app-bar-height} - #{$footer-height});

$text-colour: #9fa6b2;

$primary-colour: #30454a;
$accent-colour: #008c8c;
$teal-400: #16bdca;
$alternative-accent-colour: #d5f5f6;

* {
  font-family: Inter var, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  overflow-y: overlay;
}
.container {
  min-height: $min-height;
}

.company-search-bar {
  top: $app-bar-height;
}

.progress-loader {
  overflow: hidden;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  height: 7px;
}

.progress-loader:after {
  content: "";
  height: 7px;
  background: $teal-400;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-left: 0;
  animation: loader 1s;
  -webkit-animation: loader 1s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
}

@keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
